<template>
<div>
  <nav-bar></nav-bar>
  <div class="container-fluid page-body-wrapper">
  <side-bar></side-bar>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row align-items-center">
          <div class="col-12 col-md-4 mb-0 mb-md-0 text-center text-md-start"> <!-- Center text on small screens -->
              <div class="page-header">
                <h4 class="page-title">City List</h4>
              </div>
          </div>
          <div class="col-lg-5 ms-auto">
              <div class="d-md-flex d-grid gap-3 justify-content-md-end">
                  <router-link to="/addcity" type="button" class="btn btn-gradient-primary btn-fw">Add City</router-link>
              </div>
          </div>
      </div>
      </br>

      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body nw-opd">
              <div class="row me-1">
                 <div class="col-lg-4">
                    <input placeholder="Search" v-model="searchtext" class="form-control" type="search" name="searchcity" id="searchcity" autocomplete="off" v-on:keyup="getCityList(1)">
                 </div>
                 <h4 class="col-lg-6"></h4>
                <div class="col-lg-2 mb-lg-0 mb-2">
                    <multiselect
                        v-model="filter_status"
                        :options="status_list"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="false"
                        :preserve-search="true" placeholder=""
                        :preselect-first="true"
                        :showLabels="false"
                        :allowEmpty="false"
                        @input=getCityList(1)
                    /></multiselect>
                </div>
              </div>

              <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>City Name</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(city,i) in cities" :key="i">
                        <td>{{city.city_id}}</td>
                        <td>{{city.city_name}}</td>
                        <td><label v-bind:class="{'badge badge-success': city.city_status === 'Active',
                                                                                                                 'badge badge-danger': city.city_status === 'InActive' || city.city_status === 'Inactive'}">{{city.city_status}}</label></td>
                        <td><router-link :to="{name: 'editcity',params: { id: city.city_id}}" type="button" >
                        <button type="button" class="btn btn-gradient-primary btn-rounded btn-icon"><i class="mdi mdi-eye"></i></button></router-link> </td>
                      </tr>
                    </tbody>
                  </table>
              </div>
              <div v-if="is_API_call_running" class="text-center mt-5">
                <b-spinner label=""></b-spinner>
              </div>
              <div class="row" v-if="!is_API_call_running && cities.length == 0" >
                  <div class="col-lg-12 text-center">
                    <br/><br/><br/>
                    <small class="text-muted">{{ errorMessage }}</small>
                    <br/><br/><br/>
                  </div>
              </div>
              <div class="b-pagination-outer" v-if="listPages > 1">
                <ul id="border-pagination">
                  <li><a href="javascript:void(0)" @click="previewClick()">«</a></li>
                  <li v-for="page in listPages" :key="page"><a href="javascript:void(0)" @click="getCityList(page)" :class="(activePage && activePage==page)?'active':''">{{page}}</a></li>
                  <li><a href="javascript:void(0)" @click="nextClick()">»</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</div>
</div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import Multiselect from 'vue-multiselect';
import { mapActions, mapGetters } from "vuex";
import string from "../constant/strings.js"

export default {
  name:'cities',
  title: string.PAGE_TITLE_CITY_LIST,
  components: {
      NavBar,
Footer,
      SideBar,
     Multiselect
  },
  data() {
    return {
      cities:[],
      is_API_call_running: false,
      listPages:0,
      activePage:1,
      searchtext:'',
      myTimer:null,
      errorMessage : 'No Data Found.',
       status_list:['All','Active', 'Inactive'],
       filter_status: "All",
    }
  },
  mounted() {
    this.getCityList(this.activePage);
  },
  methods:{
    ...mapActions("hospital",["getCityListData"]),

    getCityList(page){
      this.activePage = page;
      clearTimeout(this.myTimer)
      this.myTimer = setTimeout(() => {
        this.is_API_call_running = true;
        this.cities = [];
        var bodyFormData = new FormData();
        bodyFormData.append('city_name', this.searchtext);
        bodyFormData.append('page', page);
        bodyFormData.append('filter_status', this.filter_status);
        this.getCityListData(bodyFormData).then((response) => {
            this.is_API_call_running = false
            if (response.response_code == 200) {
            this.cities = response.data;
            this.listPages = response.no_of_pages;
            }else{
              this.errorMessage = response.message;
            }
        });
      }, 500)
    },

    previewClick(){
          this.activePage--;
          if(this.activePage >= 1){
            this.getCityList(this.activePage);
          }else{
            this.activePage = 1;
          }
        },

    nextClick(){
       this.activePage++;
       if(this.activePage <= this.listPages){
            this.getCityList(this.activePage);
       }else{
            this.activePage = this.listPages;
       }
    },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
